/* You can add global styles to this file, and also import other style files */

@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-calendars/styles/material.css';
@import '~open-iconic/font/css/open-iconic-bootstrap.css';
.col-1 {
  width: 8.33%;
}

.col-2 {
  width: 16.66%;
}

.col-3 {
  width: 25%;
}

.col-4 {
  width: 33.33%;
}

.col-5 {
  width: 41.66%;
}

.col-6 {
  width: 50%;
}

.col-7 {
  width: 58.33%;
}

.col-8 {
  width: 66.66%;
}

.col-9 {
  width: 75%;
}

.col-10 {
  width: 83.33%;
}

.col-11 {
  width: 91.66%;
}

.col-12 {
  width: 100%;
}

.footer {
  /*position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem; 
  background: #42A5F5;
  color: white;*/
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color:#333333;
  color: white;
  text-align: right;
}

.customLabel {
  float: right;
}

.tokenBadge {
  display: inline-block;
  min-width: 10px;
  padding: 5px 15px;
  font-size: 15px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #337ab7;
  border-radius: 15px;
}

.divSpacing {
  padding: 1%;
}

/* Blue */
.labelCustom {
  display: inline;
  padding: 0.2em 1.6em 0.3em;
  font-size: 100%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.info {
  background-color: #337ab7;
}

.lblclosed {
  background-color: #ccc;
}

[data-title] {
  position: relative;
  z-index: 20;
  line-height: 1;
}

  [data-title]:hover::before {
    content: attr(data-title);
    position: absolute;
    bottom: -26px;
    display: inline-block;
    padding: 3px 6px;
    border-radius: 2px;
    background: #000;
    color: #fff;
    font-size: 12px;
    font-family: sans-serif;
    white-space: nowrap;
  }

  [data-title]:hover::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 8px;
    display: inline-block;
    color: #fff;
    border: 8px solid transparent;
    border-bottom: 8px solid #000;
  }

body {
  /*background-image: url('assets/images/waitinglist.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;*/
  background: rgb(2,0,36);
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(149,199,20,1) 0%, rgba(0,212,255,1) 96%);
}

.myForm {
  background-color: rgba(0,0,0,0.5) !important;
  padding: 15px !important;
  border-radius: 15px !important;
  color: white;
}

.flex-logo-div {
  /*-webkit-transform: scale(1.01);
    -moz-transform: scale(1.01);
    -ms-transform: scale(1.01);
    -o-transform: scale(1.01);
    transform: scale(1.01);*/
  /*top: 0px;*/
  background-color: white !important;
  color: #1BA9E1 !important;
  /*background: url('../images/menu/a.png');*/
  height: 48px;
  /*width: 101px;*/
  float: left;
  /*left: 50px;*/
  /*position: absolute;*/
  -webkit-box-shadow: 0px 1px 9px black;
  -moz-box-shadow: 0px 1px 9px black;
  box-shadow: 0px 1px 9px black;
}

  .flex-logo-div > h1 {
    margin-top: 4px !important;
    margin-bottom: 8px !important;
    padding-left: 5px;
    padding-right: 5px;
    text-align-last: center;
    width: 180px;
    height: 50px;
  }

.flex-logo-div1 {
  /*-webkit-transform: scale(1.01);
    -moz-transform: scale(1.01);
    -ms-transform: scale(1.01);
    -o-transform: scale(1.01);
    transform: scale(1.01);*/
  /*top: 0px;*/
  background-color: white !important;
  color: #1BA9E1 !important;
  /*background: url('../images/menu/a.png');*/
  height: 50px;
  /*width: 101px;*/
  float: left;
  /*left: 50px;*/
  /*position: absolute;*/
  -webkit-box-shadow: 0px 1px 9px black;
  -moz-box-shadow: 0px 1px 9px black;
  box-shadow: 0px 1px 9px black;
}
  .flex-logo-div1 > h1 {
    margin-top: 1px !important;
    margin-bottom: 8px !important;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 9px;
    text-align-last: center;
    width: 155px;
    height: 50px;
    font-size: 1.5rem !important;
  }
/*ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
}

li {
  float: left;
}

  li a {
    display: block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
  }

    li a:hover:not(.active) {
      background-color: #111;
    }

.active {
  background-color: #4CAF50;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}*/

body {
  margin: 0;
}

ul.topnav {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
  margin-top: -25px;
}

  ul.topnav li {
    float: left;
  }

    ul.topnav li a {
      display: block;
      color: white;
      text-align: center;
      padding: 14px 16px;
      text-decoration: none;
    }

      ul.topnav li a:hover:not(.active) {
        background-color: #111;
      }

      ul.topnav li a.active {
        background-color: #1ba9e1;
      }

    ul.topnav li.right {
      float: right;
    }

@media screen and (max-width: 600px) {
  ul.topnav li.right,
  ul.topnav li {
    float: none;
  }
}

.btn-info {
  color: #fff;
  background-color: #337ab7 !important;
  border-color: #1278d0 !important;
}

.cal-scheduler-view .cal-scheduler-headers .cal-header.cal-today {
  background: none !important;
}

.cal-scheduler-view .cal-scheduler-headers .cal-header.cal-weekend span {
  color: white !important;
}

.cal-scheduler-time{
    color:black!important;
}

.cal-scheduler-time > .cal-scheduler-time-segment {
  background-color:white!important;
}

.cal-scheduler-event-title{
    color:black!important;
}

.footer p.ng-binding{
    font-size:10px;
    margin:0px 0px 10px;
}

.zeromargin{
    margin:0px!important;
}
